import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { UserContextProvider } from './contextProviders/userContext';
import { SummaryContextProvider } from './contextProviders/summaryContext';

const WrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <UserContextProvider>
      <SummaryContextProvider>
        {element}
      </SummaryContextProvider>
    </UserContextProvider>
  );
};

export default WrapRootElement;