import React, { createContext, useState } from 'react';

interface User {
  isSignedIn: boolean;
  token: string;
}

interface UserProps extends User {
  setUser?: (newUser: User) => void;
}

const defaultUser: User = {
  isSignedIn: false,
  token: '',
}

export const UserContext = createContext({
  ...defaultUser,
  setUser: (user: User) => {},
});

export const UserContextProvider = ({ children }: { children: JSX.Element; }) => {
  const [userContext, setUserContext] = useState<User>(defaultUser);

  return (
    <UserContext.Provider
      value={{
        ...userContext,
        setUser: (newUser: User) => setUserContext(newUser),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserContextConsumer = ({ children }: { children: (user: UserProps) => JSX.Element }) => (
  <UserContext.Consumer>
    { (value: UserProps) => children(value) }
  </UserContext.Consumer>
);