import React, { createContext, useState } from 'react';
import { Summary, DomainResponse } from '../utils';

interface DefaultSummary {
  cloudflareAccount: 'standard' | 'exec';
  results: DomainResponse[];
}

const defaultSummary: DefaultSummary = {
  cloudflareAccount: 'standard',
  results: [],
}

export const SummaryContext = createContext({
  ...defaultSummary,
  setSummary: (newSummary: Summary) => {},
});

export const SummaryContextProvider = ({ children }: { children: JSX.Element; }) => {
  const [summaryContext, setSummaryContext] = useState<Summary>(defaultSummary);

  return (
    <SummaryContext.Provider
      value={{
        ...summaryContext,
        setSummary: (newSummary: Summary) => setSummaryContext(newSummary),
      }}
    >
      {children}
    </SummaryContext.Provider>
  );
};

interface SummaryProps extends Summary {
  setSummary: (newSummary: Summary) => void;
}

export const SummaryContextConsumer = ({ children }: { children: (summary: SummaryProps) => JSX.Element }) => (
  <SummaryContext.Consumer>
    { (value: SummaryProps) => children(value) }
  </SummaryContext.Consumer>
);